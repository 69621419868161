package wah.public

import kotlinx.browser.window
import kotlinx.serialization.decodeFromString
import kotlinx.serialization.encodeToString
import org.w3c.dom.get
import org.w3c.dom.set
import wah.models.DayPlan
import wah.models.Ingredient
import wah.models.Meal
import wah.models.MealPlan
import wah.models.MockData
import wah.models.Recipe
import wah.models.jsonMapper

object Storage {

    private val localStorage = window.localStorage

    fun getRecipes(): List<Recipe> {
        return MockData.getAllRecipes()
    }

    fun getIngredients(): List<Ingredient> {
        return MockData.getAllIngredients()
    }

    fun getMyRecipes(): List<Recipe> {
        return localStorage["myRecipes"]
            ?.let { jsonMapper.decodeFromString<List<Recipe>>(it) }
            ?: emptyList()
    }

    fun storeRecipe(recipe: Recipe) {
        val myRecipes = getMyRecipes()
        var existing = false
        val newRecipes = myRecipes.map { r ->
            if (r.id == recipe.id) {
                existing = true
                recipe
            } else {
                r
            }
        }.toMutableList()
        if (!existing) {
            newRecipes += recipe
        }
        localStorage["myRecipes"] = jsonMapper.encodeToString(newRecipes)
    }

    fun getRecipeById(recipeId: String): Recipe? {
        return getRecipes().plus(getMyRecipes()).firstOrNull { it.id == recipeId }
    }

    fun getMyPlan(): MealPlan {
        return localStorage["myPlan"]
            ?.let { jsonMapper.decodeFromString<MealPlan>(it) }
            ?: MealPlan(
                listOf(
                    DayPlan(
                        listOf(
                            Meal(
                                "Snacks",
                                emptyList()
                            )
                        )
                    )
                )
            )

    }

    fun storeMyPlan(plan: MealPlan) {
        localStorage["myPlan"] = jsonMapper.encodeToString(plan)
    }
}