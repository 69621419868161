package wah.public

import csstype.Display
import csstype.FlexDirection
import csstype.TextAlign
import csstype.pct
import kotlinext.js.jso
import mui.icons.material.Check
import mui.icons.material.Clear
import mui.material.Box
import mui.material.IconButton
import mui.material.ListItem
import react.FC
import react.Props
import react.useState
import wah.models.MeasuredIngredient

external interface IngredientSelectorListItemProps: Props {
    var collectMeasures: Boolean?
    var onSave: ((MeasuredIngredient?) -> Unit)?
    var onClear: (() -> Unit)?
}

val IngredientSelectorListItem = FC<IngredientSelectorListItemProps> {props ->
    val collectMeasures = props.collectMeasures ?: true
    val onSave = props.onSave ?: {}
    val onClear = props.onClear ?: {}
    var newIngredient by useState<MeasuredIngredient>()

    ListItem {
        Box {
            sx = jso {
                display = Display.flex
                flexDirection = FlexDirection.column
                width = 100.pct
            }
            IngredientsEditor {
                onIngredientChange = { newIngredient = it }
                this.collectMeasures = collectMeasures
            }
            Box {
                sx = jso {
                    textAlign = TextAlign.right
                }
                IconButton {
                    onClick = { onSave(newIngredient) }
                    Check {}
                }
                IconButton {
                    onClick = { onClear() }
                    Clear {}
                }
            }
        }
    }
}