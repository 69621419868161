package wah.public

import csstype.pt
import kotlinext.js.jso
import mui.icons.material.AddBox
import mui.icons.material.Delete
import mui.icons.material.ExpandLess
import mui.icons.material.ExpandMore
import mui.icons.material.MoreVert
import mui.material.Box
import mui.material.Collapse
import mui.material.IconButton
import mui.material.List
import mui.material.ListItem
import mui.material.ListItemButton
import mui.material.ListItemText
import mui.material.ListSubheader
import mui.material.Menu
import mui.material.MenuItem
import org.w3c.dom.Element
import react.FC
import react.Props
import react.create
import react.useState
import wah.models.MeasuredIngredient

external interface IngredientListItemProps : Props {
    var editMode: Boolean?
    var ingredient: MeasuredIngredient
    var onDelete: (() -> Unit)?
    var onAddAlternative: (() -> Unit)?
}

val IngredientListItem = FC<IngredientListItemProps> { props ->
    val editMode = props.editMode ?: false
    val onDelete = props.onDelete ?: {}
    val onAddAlternative = props.onAddAlternative ?: {}
    var menuAnchor by useState<Element?>(null)
    var alternativeOpen by useState(false)
    val i = props.ingredient

    Box {
        ListItem {
            if (editMode) {
                secondaryAction = Box.create {
                    IconButton {
                        onClick = {
                            menuAnchor = it.currentTarget
                        }
                        MoreVert { }
                    }
                    Menu {
                        anchorEl = menuAnchor?.let { a -> { a } }
                        open = menuAnchor != null
                        onClose = { menuAnchor = null }
                        MenuItem {
                            onClick = {
                                menuAnchor = null
                                onDelete()
                            }
                            Delete {}
                            +"Delete"
                        }
                        MenuItem {
                            onClick = {
                                menuAnchor = null
                                onAddAlternative()
                            }
                            AddBox {}
                            +"Add alternative"
                        }
                    }
                }
            }

            ListItemButton {
                asDynamic().onClick = { alternativeOpen = !alternativeOpen }
                ListItemText {
                    +"${i.ingredient.name} ${i.measure}"
                }
                if (!i.alternatives.isEmpty()) {
                    if (alternativeOpen) {
                        ExpandLess {}
                    } else {
                        ExpandMore {}
                    }
                }
            }

        }
        if (!i.alternatives.isEmpty()) {
            Collapse {
                `in` = alternativeOpen
                List {
                    sx = jso {
                        marginLeft = 30.pt
                    }
                    ListSubheader {
                        +"Alternatives"
                    }
                    i.alternatives.map {
                        ListItem {
                            ListItemText {
                                +"${it.name}"
                            }
                        }
                    }
                }
            }
        }
    }
}