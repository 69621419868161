package wah.public

import kotlinext.js.jso
import kotlinx.browser.document
import mui.material.Box
import mui.material.styles.Theme
import mui.material.styles.ThemeProvider
import mui.material.styles.createTheme
import react.FC
import react.PropsWithChildren
import react.RBuilder
import react.StateInstance
import react.create
import react.createContext
import react.dom.render
import react.router.NavigateFunction
import react.router.Route
import react.router.Routes
import react.router.dom.BrowserRouter
import react.useState

fun main() {
    console.log("Hello, World!")
    val root = requireNotNull(document.getElementById("root")) {
        "The root element with id=`root` is not found"
    }
    render(root) {
        app()
    }
}

fun RBuilder.app() = BrowserRouter {
    Routes {
        Route {
            attrs.path = "/"
            attrs.element = Box.create {
//                    planView()
                PlanEditorPage {}
            }
        }
        Route {
            attrs.path = "/there"
            attrs.element = Box.create {
                +"You are there. In the wild. But still hungry."
            }
        }
        Route {
            attrs {
                path = "/recipe-editor"
                element = Box.create {
                    ThemeModule {
                        RecipeEditorPage { }
                    }
                }
            }
        }
    }
}

class Nav(private val navigate: NavigateFunction) {

    fun editRecipe(recipeIndex: Int, dayIndex: Int, title: String) {
        navigate("/recipe-editor?recipe_index=${recipeIndex}&day_index=${dayIndex}&meal=${title}")
    }

    fun addRecipe(dayIndex: Int, title: String) {
        navigate("/recipe-editor?day_index=${dayIndex}&meal=${title}")
    }

    fun planEditor(dayIndex: Int, meal: String) {
        navigate("/?day_index=$dayIndex&meal=$meal")
    }
}
typealias ThemeState = StateInstance<Theme>

val ThemeContext = createContext<ThemeState>()

val ThemeModule = FC<PropsWithChildren> { props ->
    val state = useState(Themes.Light)
    val (theme) = state

    ThemeContext.Provider(state) {
        ThemeProvider {
            this.theme = theme

            props.children()
        }
    }
}

object Themes {
    val Light = createTheme(
        jso {
            palette = jso { mode = "light" }
        }
    )

    val Dark = createTheme(
        jso {
            palette = jso { mode = "dark" }
        }
    )
}