package wah.models

import kotlinx.serialization.Serializable
import kotlinx.serialization.json.Json
import kotlinx.serialization.modules.SerializersModule
import kotlinx.serialization.modules.polymorphic

val WahModels = SerializersModule {
    polymorphic(Package::class) {
        subclass(Pack::class, Pack.serializer())
    }
}
val jsonMapper = Json {
    serializersModule = WahModels
}

interface Entity<T> {
    val id: String
}

val Number.g
    get() = Weight(this.toDouble(), WeightUnit.g)

val Number.ml
    get() = Volume(this.toDouble(), VolumeUnit.ml)

val Number.min
    get() = Time(this.toDouble(), TimeUnit.min)

@Serializable
data class Weight(
    val amount: Double,
    val unit: WeightUnit
) {
    override fun toString(): String {
        return "$amount $unit"
    }
}

operator fun Weight.plus(that: Weight): Weight {
    require(this.unit == that.unit) { "Can't sum up different units this=$this, that=$that" }
    return Weight(this.amount + that.amount, this.unit)
}

operator fun Weight.div(that: Weight): Double {
    require(this.unit == that.unit) { "Can't divide different units this=$this, that=$that" }
    return this.amount / that.amount
}

@Serializable
data class Volume(
    val amount: Double,
    val unit: VolumeUnit
){
    override fun toString(): String {
        return "$amount $unit"
    }
}

operator fun Volume.plus(that: Volume): Volume {
    require(this.unit == that.unit) { "Can't sum up different units this=$this, that=$that" }
    return Volume(this.amount + that.amount, this.unit)
}

@Serializable
data class Time(
    val amount: Double,
    val unit: TimeUnit
) {
    override fun toString(): String {
        return "$amount $unit"
    }
}

operator fun Time.plus(that: Time): Time {
    require(this.unit == that.unit) { "Can't sum up different units this=$this, that=$that" }
    return Time(this.amount + that.amount, this.unit)
}

@Serializable
data class Recipe(
    override val id: String,
    val title: String,
    val ingredients: List<MeasuredIngredient>,
    val instructions: List<Instruction>,
    val pictureUrl: String,
) : Entity<Recipe>

@Serializable
data class Instruction(
    val content: String,
    val waterRequired: Volume,
    val cookTime: Time
)

@Serializable
data class MeasuredIngredient(
    val ingredient: Ingredient,
    val measure: Weight,
    val alternatives: List<Ingredient> = emptyList(),
)

@Serializable
data class Ingredient(
    override val id: String,
    val name: String,
    val nutritionalInfo: NutritionalInfo,
    val packaging: List<Package>
) : Entity<Ingredient>

enum class WeightUnit {
    g,
    oz,
    ml,
}

enum class VolumeUnit {
    ml,
    l
}

enum class TimeUnit {
    min,
    hour
}

@Serializable
data class MealPlan(
    val days: List<DayPlan>
)

@Serializable
data class DayPlan(
    val meals: List<Meal>
)

@Serializable
data class Meal(
    val title: String,
    val recipes: List<Recipe>
)

@Serializable
data class NutritionalInfo(
    val calories: Double,
    val servingSize: Weight
) {
    fun calories(weight: Weight): Double {
        require(weight.unit == servingSize.unit)
        val perUnit = calories / servingSize.amount
        return perUnit * weight.amount
    }
}

interface Package

@Serializable
data class Pack(
    val piecesPerPack: Int,
    val servingsPerPiece: Int
) : Package

data class ByWeight(
    val weight: Weight
)

fun DayPlan.calories(): Double {
    return this.meals.sumOf { it.calories() }
}

fun DayPlan.cookTime(): Time {
    return this.meals.fold(0.min) { acc, it -> acc + it.cookTime() }
}

fun Meal.calories(): Double {
    return this.recipes.sumOf { it.calories() }
}

fun Meal.cookTime(): Time {
    return this.recipes.fold(0.min) { acc, it -> acc + it.cookTime() }
}

fun Recipe.cookTime(): Time {
    return this.instructions.fold(0.min) { acc, it -> acc + it.cookTime }
}

fun Recipe.calories(): Double {
    return this.ingredients.sumOf { it.ingredient.nutritionalInfo.calories(it.measure) }
}

fun Recipe.waterRequired(): Volume {
    return this.instructions.fold(0.ml) {acc, it -> acc + it.waterRequired}
}
val NEW_RECIPE = Recipe(
    id = "",
    title = "<new>",
    ingredients = emptyList(),
    instructions = emptyList(),
    pictureUrl = "",
)

val NO_RECIPE = Recipe(
    id = "",
    title = "",
    ingredients = emptyList(),
    instructions = emptyList(),
    pictureUrl = "",
)

val NO_INGREDIENT = Ingredient("", "", NutritionalInfo(0.0, 0.g), emptyList())

val ZERO_INGREDIENT = MeasuredIngredient(
    NO_INGREDIENT,
    0.g
)

val NO_INSTRUCTION = Instruction("", 0.ml, 0.min)