package wah.public

import csstype.pt
import kotlinext.js.jso
import mui.material.Box
import mui.material.MenuItem
import mui.material.TextField
import org.w3c.dom.HTMLInputElement
import org.w3c.dom.HTMLSelectElement
import react.FC
import react.Props
import react.create
import react.createElement
import react.dom.onChange
import react.key
import react.useState
import wah.models.Instruction
import wah.models.Time
import wah.models.TimeUnit
import wah.models.Volume
import wah.models.VolumeUnit

external interface InstructionsEditorProps : Props {
    var waterRequired: Volume?
    var cookTime: Time?
    var content: String?
    var onChange: (Instruction) -> Unit
}

val InstructionsEditor = FC<InstructionsEditorProps> { props ->

    var waterRequired by useState(props.waterRequired?.amount ?: 0.0)
    var waterUnit by useState(props.waterRequired?.unit ?: VolumeUnit.ml)
    var cookTime by useState(props.cookTime?.amount ?: 0.0)
    var timeUnit by useState(props.cookTime?.unit ?: TimeUnit.min)
    var instruction by useState(props.content ?: "")

    fun instructionChanged(
        newWaterRequired: Double = waterRequired,
        newWaterUnit: VolumeUnit = waterUnit,
        newCookTime: Double = cookTime,
        newTimeUnit: TimeUnit = timeUnit,
        newInstruction: String = instruction
    ) {
        props.onChange(
            Instruction(
                newInstruction,
                Volume(newWaterRequired, newWaterUnit),
                Time(newCookTime, newTimeUnit)
            )
        )
    }

    Box {
        Box {
            sx = jso {
                marginBottom = 20.pt
            }
            TextField {
                label = Box.create { +"Instruction" }
                fullWidth = true
                value = instruction
                onChange = { e ->
                    val v = e.target.unsafeCast<HTMLInputElement>().value
                    instruction = v
                    instructionChanged(newInstruction = v)
                }
            }
        }
        Box {
            TextField {
                label = Box.create { +"Water required" }
                value = waterRequired
                onChange = { e ->
                    val v = (e.target.unsafeCast<HTMLInputElement>()
                        .value
                        .toDoubleOrNull()
                        ?: 0.0)
                    waterRequired = v
                    instructionChanged(newWaterRequired = v)
                }
            }
            TextField {
                sx = jso {
                    width = 100.pt
                    marginLeft = 5.pt
                }
                select = true
                label = Box.create { +"Unit" }
                value = waterUnit.name
                onChange = { e ->
                    val v = VolumeUnit.valueOf(e.target.unsafeCast<HTMLSelectElement>().value)
                    waterUnit = v
                    instructionChanged(newWaterUnit = v)
                }
                VolumeUnit.values().map {
                    MenuItem {
                        key = it.name
                        value = it.name
                        +it.name
                    }
                }
            }

            TextField {
                sx = jso {
                    marginLeft = 10.pt
                }
                label = Box.create { +"Time needed" }
                value = cookTime
                onChange = { e ->
                    val v = (e.target.unsafeCast<HTMLInputElement>()
                        .value
                        .toDoubleOrNull()
                        ?: 0.0)
                    cookTime = v
                    instructionChanged(newCookTime = v)
                }
            }
            TextField {
                sx = jso {
                    width = 100.pt
                    marginLeft = 5.pt
                }
                select = true
                label = Box.create { +"Unit" }
                value = timeUnit.name
                onChange = { e ->
                    val v = TimeUnit.valueOf(e.target.unsafeCast<HTMLSelectElement>().value)
                    timeUnit = v
                    instructionChanged(newTimeUnit = v)
                }
                TimeUnit.values().map {
                    MenuItem {
                        key = it.name
                        value = it.name
                        +it.name
                    }
                }
            }
        }
    }
}