package wah.public

import csstype.AlignContent
import csstype.AlignItems
import csstype.Display
import csstype.FlexDirection
import csstype.Margin
import csstype.pct
import csstype.pt
import csstype.px
import kotlinext.js.jso
import mui.icons.material.Add
import mui.icons.material.KeyboardArrowRight
import mui.material.Accordion
import mui.material.AccordionDetails
import mui.material.AccordionSummary
import mui.material.Avatar
import mui.material.Box
import mui.material.Divider
import mui.material.IconButton
import mui.material.List
import mui.material.ListItem
import mui.material.ListItemAvatar
import mui.material.ListItemButton
import mui.material.ListItemSecondaryAction
import mui.material.ListItemText
import mui.material.Typography
import react.FC
import react.Props
import react.create
import react.dom.events.SyntheticEvent
import react.dom.html.ReactHTML
import react.router.useNavigate
import react.useState
import wah.models.calories
import wah.models.cookTime

private val pageMinWidth = 60.pct
private val pageMaxWidth = 700.px

val PlanEditorPage = FC<Props> { _ ->

    val plan by useState { Storage.getMyPlan() }
    val nav = Nav(useNavigate())
    var expandedPanel by useState("")

    fun accordionExpandHandler(panel: String): (SyntheticEvent<*, *>, Boolean) -> Unit {
        return { _, isExpanded -> expandedPanel = if (isExpanded) panel else "" }
    }

    Box {
        sx = jso {
            alignContent = AlignContent.center
            width = 100.pct
            display = Display.flex
            flexDirection = FlexDirection.column
            alignItems = AlignItems.center
        }

        plan.days
            .mapIndexed { dayIndex, dayPlan ->
                Box {
                    sx = jso {
                        this.marginTop = 20.pt
                        this.minWidth = pageMinWidth
                        this.maxWidth = pageMaxWidth
                    }
                    Divider {
                        sx = jso {
                            margin = Margin(5.pt, 0.pt)
                        }
                        Typography {
                            variant = "h6"
                            +"Day ${dayIndex + 1}"
                        }

                        Typography {
                            variant = "caption"
                            +"${dayPlan.calories().toInt()}cal/${dayPlan.cookTime()}"
                        }
                    }
                    dayPlan.meals.map { meal ->
                        val panel = "$dayIndex.${meal.title}"
                        Accordion {
                            expanded = expandedPanel == panel
                            onChange = accordionExpandHandler(panel)
                            AccordionSummary {
                                Typography {
                                    Typography {
                                        variant = "subtitle"
                                        component = ReactHTML.div
                                        +meal.title
                                    }
                                    Typography {
                                        variant = "caption"
                                        component = ReactHTML.div
                                        +"${meal.calories().toInt()}cal/${meal.cookTime()}"
                                    }
                                }
                            }
                            AccordionDetails {
                                List {
                                    meal.recipes.mapIndexed { recipeIdx, recipe ->
                                        ListItem {
                                            ListItemButton {
                                                this.asDynamic().onClick = {
                                                    nav.editRecipe(recipeIdx, dayIndex, meal.title)
                                                }
                                                ListItemAvatar {
                                                    Avatar {
                                                        sx = jso {
                                                            width = 40.px
                                                            height = 40.px
                                                        }
                                                        src = recipe.pictureUrl
                                                    }
                                                }
                                                ListItemText {
                                                    primary = Typography.create {
                                                        variant = "subtitle"
                                                        component = ReactHTML.div
                                                        +recipe.title
                                                    }
                                                    secondary = Typography.create {
                                                        variant = "caption"
                                                        component = ReactHTML.div
                                                        +"${recipe.calories().toInt()}cal/${recipe.cookTime()}"
                                                    }
                                                }
                                                ListItemSecondaryAction {
                                                    IconButton {
                                                        KeyboardArrowRight {}
                                                    }
                                                }
                                            }
                                        }
                                    }

                                    ListItem {
                                        IconButton {
                                            onClick = { nav.addRecipe(dayIndex, meal.title) }
                                            Add {}
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
    }
}