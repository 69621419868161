package wah.public

import csstype.AlignContent
import csstype.AlignItems
import csstype.Display
import csstype.FlexDirection
import csstype.Margin
import csstype.Position
import csstype.TextAlign
import csstype.VerticalAlign
import csstype.pct
import csstype.pt
import csstype.px
import kotlinext.js.jso
import mui.icons.material.AccessTime
import mui.icons.material.Add
import mui.icons.material.Cancel
import mui.icons.material.Check
import mui.icons.material.Clear
import mui.icons.material.Delete
import mui.icons.material.Edit
import mui.icons.material.ExpandMore
import mui.icons.material.FormatListNumbered
import mui.icons.material.LocalDrink
import mui.icons.material.Save
import mui.material.Autocomplete
import mui.material.AutocompleteProps
import mui.material.Box
import mui.material.Card
import mui.material.CardActions
import mui.material.CardContent
import mui.material.CardHeader
import mui.material.CardMedia
import mui.material.Collapse
import mui.material.Fab
import mui.material.FabColor
import mui.material.Grid
import mui.material.IconButton
import mui.material.List
import mui.material.ListItem
import mui.material.ListItemText
import mui.material.Size
import mui.material.SvgIconSize
import mui.material.TextField
import mui.material.Typography
import org.w3c.dom.HTMLInputElement
import react.FC
import react.Props
import react.create
import react.dom.aria.ariaExpanded
import react.dom.aria.ariaLabel
import react.dom.html.ReactHTML
import react.dom.html.ReactHTML.div
import react.dom.onChange
import react.router.dom.useSearchParams
import react.router.useNavigate
import react.useState
import wah.models.Instruction
import wah.models.MeasuredIngredient
import wah.models.NEW_RECIPE
import wah.models.NO_INGREDIENT
import wah.models.NO_INSTRUCTION
import wah.models.NO_RECIPE
import wah.models.Recipe
import wah.models.calories
import wah.models.cookTime
import wah.models.waterRequired
import kotlin.math.absoluteValue
import kotlin.random.Random


private val pageMinWidth = 60.pct
private val pageMaxWidth = 700.px

external interface RecipeEditorPageProps : Props {
    var dayIndex: Int?
    var meal: String?
    var recipeId: String?
    var recipeIndex: Int?

    var selectedRecipe: Recipe?
    var instructionsExpanded: Boolean?
    var customizeMode: Boolean?
    var newInstruction: Instruction?
}

val RecipeEditorPage = FC<RecipeEditorPageProps> { props ->
    val searchParams by useSearchParams()
    val dayIndex = props.dayIndex ?: searchParams.get("day_index")?.toInt()
    val meal = props.meal ?: searchParams.get("meal")
    val recipeIndex = props.recipeIndex ?: searchParams.get("recipe_index")?.toInt()
    val recipeId = props.recipeId ?: searchParams.get("recipe_id")
    val nav = Nav(useNavigate())

    val selectedRecipe =
        recipeId?.let { Storage.getRecipeById(it) }
            ?: recipeIndex
                ?.let { rIdx ->
                    Storage.getMyPlan()
                        .days[dayIndex!!]
                        .meals.first { it.title == meal }
                        .recipes[rIdx].id.let { Storage.getRecipeById(it) }
                }
            ?: props.selectedRecipe?.copy(id = "")
            ?: NO_RECIPE
    var initialRecipe by useState(selectedRecipe)
    var recipe by useState(selectedRecipe)
    var recipeOption by useState(RecipeOption(selectedRecipe))
    var customizeMode by useState(props.customizeMode ?: false)
    var instructionsAreaExpanded by useState(props.instructionsExpanded ?: false)
    var newInstruction: Instruction? by useState(props.newInstruction)
    var newIngredient by useState(false)
    var newAlternativeIngredientIndex: Int? by useState(null)

    fun chooseRecipe(option: RecipeOption?) {
        val r = if (option == null || option.recipeId == NEW_RECIPE.id) {
            NEW_RECIPE
        } else {
            requireNotNull(Storage.getRecipeById(option.recipeId)) { "Recipe ${option.recipeId} is not found" }
        }.let {
            if (option?.my != true && it != NEW_RECIPE) {
                it.copy(id = "")
            } else {
                it
            }
        }
        recipe = r
        initialRecipe = r
        customizeMode = false
    }

    fun customize() {
        customizeMode = true
    }

    fun commitCustomization() {
        val r = if (recipe.id.isBlank()) {
            recipe.copy(id = Random.nextLong().absoluteValue.toString(36))
        } else {
            recipe
        }
        customizeMode = false
        recipe = r
        initialRecipe = r
        recipeOption = RecipeOption(r, true)

        Storage.storeRecipe(r)
    }

    fun discardCustomization() {
        customizeMode = false
        recipe = initialRecipe
        newInstruction = null
        newIngredient = false
    }

    fun addInstruction() {
        newInstruction = NO_INSTRUCTION
    }

    fun saveInstruction() {
        val inst = newInstruction
        if (inst != null && inst != NO_INSTRUCTION) {
            recipe = recipe.copy(instructions = recipe.instructions.plus(inst))
        }
        newInstruction = null
    }

    fun discardInstruction() {
        newInstruction = null
    }

    fun deleteInstruction(index: Int) {
        val dest = ArrayList<Instruction>(recipe.instructions.size - 1)
        recipe = recipe.copy(instructions = recipe.instructions.filterIndexedTo(dest) { i, _ -> i != index })
    }

    fun addIngredient() {
        newIngredient = true
        newAlternativeIngredientIndex = null
    }

    fun saveIngredient(ing: MeasuredIngredient?) {
        if (ing != null && ing.ingredient != NO_INGREDIENT) {
            recipe = recipe.copy(ingredients = recipe.ingredients.plus(ing))
        }
        newIngredient = false
    }

    fun discardIngredient() {
        newIngredient = false
    }

    fun deleteIngredient(index: Int) {
        val dest = ArrayList<MeasuredIngredient>(recipe.ingredients.size - 1)
        recipe = recipe.copy(ingredients = recipe.ingredients.filterIndexedTo(dest) { i, _ -> i != index })
    }

    fun addAlternativeIngredient(index: Int) {
        newAlternativeIngredientIndex = index
    }

    fun saveAlternativeIngredient(ing: MeasuredIngredient?) {
        if (ing != null) {
            recipe = recipe.copy(ingredients = recipe.ingredients.mapIndexed { index, ingredient ->
                if (index == newAlternativeIngredientIndex) {
                    ingredient.copy(alternatives = ingredient.alternatives.plus(ing.ingredient))
                } else {
                    ingredient
                }
            })
        }
        newAlternativeIngredientIndex = null
    }

    fun discardAlternativeIngredient() {
        newAlternativeIngredientIndex = null
    }

    fun saveMealChanges() {
        val myPlan = Storage.getMyPlan()
        val newPlan = myPlan.copy(
            days = myPlan.days.mapIndexed { idx, dayPlan ->
                if (idx == dayIndex) {
                    dayPlan.copy(meals = dayPlan.meals.map { m ->
                        if (m.title == meal) {
                            m.copy(
                                recipes = if (recipeIndex == null) {
                                    m.recipes +
                                            (if (recipe.id.isBlank()) recipe.copy(id = recipeOption.recipeId)
                                            else recipe)
                                } else {
                                    m.recipes.mapIndexed { idx, r ->
                                        if (idx == recipeIndex) {
                                            if (recipe.id.isBlank()) recipe.copy(id = recipeOption.recipeId)
                                            else recipe
                                        } else {
                                            r
                                        }
                                    }
                                })
                        } else {
                            m
                        }
                    })
                } else {
                    dayPlan
                }
            }
        )
        Storage.storeMyPlan(newPlan)
        nav.planEditor(dayIndex!!, meal!!)
    }

    fun discardMealChanges() {
        nav.planEditor(dayIndex!!, meal!!)
    }

    if (meal != null && dayIndex != null) {
        Box {
            sx = jso {
                alignContent = AlignContent.center
                width = 100.pct
                display = Display.flex
                flexDirection = FlexDirection.column
                alignItems = AlignItems.center
            }

            Box {
                sx = jso {
                    alignContent = AlignContent.center
                    display = Display.flex
                    flexDirection = FlexDirection.row
                    alignItems = AlignItems.center
                }

                Typography {
                    sx = jso {
                        margin = Margin(0.pt, 5.pt)
                    }
                    variant = "h5"
                    +"Recipe for Day ${dayIndex + 1}: $meal"
                }

                if (!customizeMode) {
                    Box {
                        sx = jso {
                            position = Position.fixed
                            bottom = 3.pct
                            right = 3.pct
                        }
                        Fab {
                            sx = jso {
                                margin = Margin(0.pt, 5.pt)
                            }
                            color = FabColor.primary
                            onClick = { saveMealChanges() }
                            Check {}
                        }

                        Fab {
                            sx = jso {
                                margin = Margin(0.pt, 5.pt)
                            }
                            color = FabColor.secondary
                            onClick = { discardMealChanges() }
                            Clear {}
                        }
                    }
                }
            }

            Box {
                sx = jso {
                    this.marginTop = 20.pt
                    this.minWidth = pageMinWidth
                    this.maxWidth = pageMaxWidth
                }

                @Suppress("UPPER_BOUND_VIOLATED")
                Autocomplete<AutocompleteProps<RecipeOption>> {
                    disablePortal = true
                    value = recipeOption
                    options = recipeOptions()
                    onChange = { _, value, _, _ ->
                        val option = value.unsafeCast<RecipeOption?>()
                        chooseRecipe(option)
                        if (option != null) recipeOption = option
                    }
                    isOptionEqualToValue = { o1, o2 -> o1.recipeId == o2.recipeId }
                    renderInput = { params ->
                        TextField.create {
                            +params
                            label = Box.create { +"Look up recipe" }
                        }
                    }
                }
            }

            Box {
                sx = jso {
                    this.marginTop = 20.pt
                    this.minWidth = pageMinWidth
                    this.maxWidth = pageMaxWidth
                }
                if (recipe != NO_RECIPE) {
                    Card {
                        sx = jso {
                            this.minWidth = pageMinWidth
                        }

                        CardHeader {
                            title =
                                if (customizeMode) {
                                    TextField.create {
                                        label = Box.create { +"Title" }
                                        value = if (recipe == NEW_RECIPE) "" else recipe.title
                                        onChange = {
                                            recipe =
                                                recipe.copy(title = it.currentTarget.unsafeCast<HTMLInputElement>().value)
                                        }
                                        fullWidth = true
                                    }
                                } else {
                                    Typography.create {
                                        +recipe.title
                                    }
                                }
                            subheader = Box.create {
                                +"${recipe.cookTime()} / ${recipe.waterRequired()} / ${recipe.calories().toInt()}cal"
                            }
                            action = Box.create {
                                if (customizeMode) {
                                    IconButton {
                                        onClick = { commitCustomization() }
                                        Save {}
                                    }
                                    IconButton {
                                        onClick = { discardCustomization() }
                                        Cancel {}
                                    }
                                } else {
                                    IconButton {
                                        onClick = { customize() }
                                        Edit {}
                                    }
                                }
                            }
                        }

                        CardMedia {
                            component = ReactHTML.img
                            image = recipe.pictureUrl
                        }

                        CardContent {
                            List {
                                ListItem {
                                    if (customizeMode && newAlternativeIngredientIndex == null && !newIngredient) {
                                        secondaryAction = Box.create {
                                            IconButton {
                                                onClick = { addIngredient() }
                                                Add {}
                                            }
                                        }
                                    }
                                    Typography {
                                        variant = "h6"
                                        +"Ingredients"
                                    }
                                }
                                if (recipe.ingredients.isEmpty()) {
                                    ListItem {
                                        Typography {
                                            variant = "p"
                                            +"Not yet"
                                        }
                                    }
                                } else {
                                    recipe.ingredients.mapIndexed { index, i ->
                                        Box {
                                            IngredientListItem {
                                                ingredient = i
                                                editMode =
                                                    customizeMode && !newIngredient && newAlternativeIngredientIndex == null
                                                onDelete = { deleteIngredient(index) }
                                                onAddAlternative = { addAlternativeIngredient(index) }
                                            }
                                            if (index == newAlternativeIngredientIndex) {
                                                IngredientSelectorListItem {
                                                    collectMeasures = false
                                                    onSave = { saveAlternativeIngredient(it) }
                                                    onClear = { discardAlternativeIngredient() }
                                                }
                                            }
                                        }
                                    }
                                }
                                if (newIngredient) {
                                    IngredientSelectorListItem {
                                        collectMeasures = true
                                        onSave = { saveIngredient(it) }
                                        onClear = { discardIngredient() }
                                    }
                                }
                            }
                        }

                        CardActions {
                            ExpandMore {
                                ariaExpanded = instructionsAreaExpanded
                                onClick = { instructionsAreaExpanded = !instructionsAreaExpanded }
                                ariaLabel = "Show instructions"

                                this.children = FormatListNumbered.create {}
                            }
                        }

                        Collapse {
                            `in` = instructionsAreaExpanded
                            CardContent {
                                List {
                                    ListItem {
                                        if (customizeMode && newInstruction == null) {
                                            secondaryAction = Box.create {
                                                IconButton {
                                                    size = Size.small
                                                    onClick = { addInstruction() }
                                                    Add {}
                                                }
                                            }
                                        }
                                        Typography {
                                            variant = "h6"
                                            +"Instructions"
                                        }
                                    }
                                    if (recipe.instructions.isEmpty()) {
                                        ListItem {
                                            Typography {
                                                variant = "p"
                                                +"Not yet"
                                            }
                                        }
                                    } else {
                                        recipe.instructions.mapIndexed { index, instruction ->
                                            ListItem {
                                                if (customizeMode) {
                                                    secondaryAction = Box.create {
                                                        IconButton {
                                                            onClick = { deleteInstruction(index) }
                                                            Delete {}
                                                        }
                                                    }
                                                }
                                                ListItemText {
                                                    Grid {
                                                        container = true
                                                        sx = jso {
                                                            alignItems = AlignItems.center
                                                        }
                                                        Grid {
                                                            item = true
                                                            xs = true
                                                            +"${index + 1}."
                                                        }
                                                        Grid {
                                                            item = true
                                                            xs = 9
                                                            +instruction.content
                                                        }
                                                        Grid {
                                                            item = true
                                                            xs = 2
                                                            Box {
                                                                sx = jso {
                                                                    verticalAlign = VerticalAlign.middle
                                                                    display = Display.flex
                                                                    padding = 2.pt
                                                                }
                                                                LocalDrink {
                                                                    this.fontSize = SvgIconSize.small
                                                                }
                                                                +"${instruction.waterRequired}"
                                                            }
                                                            Box {
                                                                sx = jso {
                                                                    verticalAlign = VerticalAlign.middle
                                                                    display = Display.flex
                                                                    padding = 2.pt
                                                                }
                                                                AccessTime {
                                                                    this.fontSize = SvgIconSize.small
                                                                }
                                                                +"${instruction.cookTime}"
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    if (newInstruction != null) {
                                        ListItem {
                                            Box {
                                                sx = jso {
                                                    display = Display.flex
                                                    flexDirection = FlexDirection.column
                                                    width = 100.pct
                                                }
                                                InstructionsEditor {
                                                    onChange = { i -> newInstruction = i }
                                                }
                                                Box {
                                                    sx = jso {
                                                        display = Display.block
                                                        textAlign = TextAlign.right
                                                    }
                                                    IconButton {
                                                        onClick = { saveInstruction() }
                                                        Check {}
                                                    }
                                                    IconButton {
                                                        onClick = { discardInstruction() }
                                                        Clear {}
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    } else {
        div {
            +"Not found"
        }
    }
}

private external interface RecipeOption {
    var label: String
    var recipeId: String
    var my: Boolean
}


private fun RecipeOption(recipe: Recipe, my: Boolean = false): RecipeOption = jso {
    this.label = (if (my) "[my] " else "") + recipe.title
    this.recipeId = recipe.id
    this.my = my
}

private fun recipeOptions() = (listOf(NEW_RECIPE) + Storage.getRecipes())
    .map { RecipeOption(it) }
    .plus(Storage.getMyRecipes().map { RecipeOption(it, my = true) })
    .toTypedArray()

