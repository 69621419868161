package wah.models

object MockData {

    val instantOatMeal = Ingredient(
        id = "instant-oatmeal",
        name = "Instant Oatmeal",
        nutritionalInfo = NutritionalInfo(110.0, 30.g),
        packaging = listOf(Pack(1, 1), Pack(20, 1))
    )

    val teaBag = Ingredient(
        id = "tea-bag",
        name = "Tea Bag assorted",
        nutritionalInfo = NutritionalInfo(0.0, 2.g),
        packaging = listOf(Pack(20, 1), Pack(40, 1))
    )

    val anyGranolaBar = Ingredient(
        id = "any-granola-bar",
        name = "Pretty much any granola bar",
        nutritionalInfo = NutritionalInfo(190.0, 42.g),
        packaging = listOf(Pack(1, 1), Pack(10, 1))
    )

    val mountainHouseRiceAndChickenIngredient = Ingredient(
        id = "mountain-house-rice-and-chicken-ingredient-1",
        name = "Rice and Chicken by Mountain House",
        nutritionalInfo = NutritionalInfo(500.0, 60.g),
        packaging = listOf(Pack(1, 1))
    )

    fun getAllIngredients(): List<Ingredient> =
        listOf(instantOatMeal, teaBag, anyGranolaBar, mountainHouseRiceAndChickenIngredient)

    /*** RECIPES ***/

    val oatmeal = Recipe(
        id = "oatmeal-zdfs",
        title = "Oatmeal",
        ingredients = listOf(
            MeasuredIngredient(instantOatMeal, 30.g)
        ),
        instructions = listOf(
            Instruction("Boil the water", 100.ml, 3.min),
            Instruction("Unseal the package", 0.ml, 0.min),
            Instruction("Pour into the boiling water and wait", 0.ml, 5.min),
        ),
        pictureUrl = "https://external-content.duckduckgo.com/iu/?u=http%3A%2F%2Fwww.skinnykitchen.com%2Fwp-content%2Fuploads%2F2012%2F03%2Foatmeal1-150x150.jpg&f=1&nofb=1",
    )

    val tea = Recipe(
        id = "tea-1",
        title = "Tea",
        ingredients = listOf(
            MeasuredIngredient(teaBag, 2.g)
        ),
        instructions = listOf(
            Instruction("Boil the water", 300.ml, 5.min),
            Instruction("Pour the tea bag into the cup and wait", 0.ml, 5.min),
        ),
        pictureUrl = "https://external-content.duckduckgo.com/iu/?u=http%3A%2F%2Fwww.nvtc.ee%2Fe-oppe%2FMarina%2Fjoogid%2FHerbal_tea.jpg&f=1&nofb=1",
    )

    val granolaBar = Recipe(
        id = "granola-bar-1",
        title = "Granola Bar",
        ingredients = listOf(
            MeasuredIngredient(anyGranolaBar, 42.g)
        ),
        instructions = listOf(
            Instruction("Unwrap it", 0.ml, 0.min)
        ),
        pictureUrl = "https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fmedia1.popsugar-assets.com%2Ffiles%2Fthumbor%2FMgvY8Cv7YqWX7nR7njvuZETe5yc%2F160x160%2Ffilters%3Aformat_auto-!!-%3Astrip_icc-!!-%3Asharpen-!1%2C0%2Ctrue!-%2F2012%2F08%2F34%2F1%2F192%2F1922729%2F503c595166ee2811_plantation_bars%2Fi%2Fgluten-free-granola-bars-perfect-balance-between-sweet.JPG&f=1&nofb=1",
    )

    val mountainHouseRiceAndChicken = Recipe(
        id = "mountain-house-rice-and-chicken",
        title = "Mountain House Rice & Chicken Pouch",
        ingredients = listOf(
            MeasuredIngredient(mountainHouseRiceAndChickenIngredient, 60.g)
        ),
        instructions = listOf(
            Instruction("Open package at tear notch. Remove and discard oxygen absorber", 0.ml, 0.min),
            Instruction("Carefully add boiling water", 325.ml, 5.min),
            Instruction("Stir carefully and close zipper", 0.ml, 0.min),
            Instruction("Wait 5 minutes, stir again and reseal", 0.ml, 5.min),
            Instruction("Let stand an additional 4 minutes. Stir and enjoy right out of the pouch", 0.ml, 4.min)
        ),
        pictureUrl = "https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fi5.walmartimages.com%2Fasr%2Fc7893fcd-309d-44ae-ae63-4277cee43736_1.178cc55cc9342b15d9c85d4ecebe9fc0.jpeg%3FodnHeight%3D180%26odnWidth%3D180%26odnBg%3DFFFFFF&f=1&nofb=1",
    )

    fun getAllRecipes(): List<Recipe> = listOf(oatmeal, tea, granolaBar, mountainHouseRiceAndChicken)

    /*********** PLANS ************/

    val plan1 = MealPlan(
        days = listOf(
            DayPlan(
                meals = listOf(
                    Meal(
                        title = "Breakfast",
                        recipes = listOf(
                            oatmeal,
                            tea
                        )
                    ),
                    Meal(
                        title = "Lunch",
                        recipes = listOf(
                            granolaBar
                        )
                    ),
                    Meal(
                        title = "Dinner",
                        recipes = listOf(
                            mountainHouseRiceAndChicken,
                            tea
                        )
                    )
                )
            ),
            DayPlan(
                meals = listOf(
                    Meal(
                        title = "Breakfast",
                        recipes = listOf(
                            oatmeal,
                            tea
                        )
                    ),
                    Meal(
                        title = "Lunch",
                        recipes = listOf(
                            granolaBar
                        )
                    ),
                    Meal(
                        title = "Dinner",
                        recipes = listOf(
                            mountainHouseRiceAndChicken,
                            tea
                        )
                    )
                )
            )
        )
    )

}