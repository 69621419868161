package wah.public

import io.ktor.client.HttpClient
import io.ktor.client.features.json.JsonFeature
import io.ktor.client.features.json.serializer.KotlinxSerializer
import io.ktor.client.request.get
import io.ktor.http.encodeURLQueryComponent
import wah.models.Ingredient
import wah.models.jsonMapper


object Api {

    private val base = Config.apiLocation

    private val client = HttpClient() {
        install(JsonFeature) {
            serializer = KotlinxSerializer(jsonMapper)
        }
    }

    suspend fun findIngredients(query: String): List<Ingredient> {
        return client.get("$base/api/1/ingredients?query=${query.encodeURLQueryComponent()}")
    }
}