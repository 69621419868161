package wah.public

import csstype.Display
import csstype.FlexDirection
import csstype.LineHeight
import csstype.pct
import csstype.pt
import kotlinext.js.jso
import kotlinx.browser.window
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import mui.material.Autocomplete
import mui.material.AutocompleteProps
import mui.material.Box
import mui.material.MenuItem
import mui.material.TextField
import org.w3c.dom.HTMLInputElement
import react.FC
import react.Props
import react.create
import react.dom.onChange
import react.key
import react.useEffect
import react.useState
import wah.models.Ingredient
import wah.models.MeasuredIngredient
import wah.models.NO_INGREDIENT
import wah.models.Weight
import wah.models.WeightUnit

external interface IngredientsEditorProps : Props {
    var onIngredientChange: (MeasuredIngredient) -> Unit
    var collectMeasures: Boolean?
}

val IngredientsEditor = FC<IngredientsEditorProps> { props ->
    val collectMeasures = props.collectMeasures ?: true
    var unit by useState(WeightUnit.g)
    var ingredient by useState(NO_INGREDIENT)
    var amount by useState(0.0)
    var inputValue by useState("")
    var ingredientOptions by useState(emptyArray<IngredientSelectOption>())

    fun ingredientChanged(
        newIngredient: Ingredient = ingredient,
        newAmount: Double = amount,
        newUnit: WeightUnit = unit
    ) {
        props.onIngredientChange(MeasuredIngredient(newIngredient, Weight(newAmount, newUnit)))
    }

    useEffect(inputValue) {
        GlobalScope.launch {
            ingredientOptions = Api.findIngredients(inputValue).map { option(it)!! }.toTypedArray()
        }
    }

    Box {
        sx = jso {
            display = Display.flex
            flexDirection = FlexDirection.column
            width = 100.pct
            lineHeight = "4.5".unsafeCast<LineHeight>()
        }
        Box {
            @Suppress("UPPER_BOUND_VIOLATED")
            Autocomplete<AutocompleteProps<IngredientSelectOption>> {
                options = ingredientOptions
                value = option(ingredient)
                filterOptions = { a, _ -> a }
                onInputChange = { _, value, _ -> inputValue = value }
                onChange = { _, value, _, _ ->
                    window.setTimeout({
                        val newIngredient = ingredient(value.unsafeCast<IngredientSelectOption>()) ?: NO_INGREDIENT
                        ingredient = newIngredient
                        ingredientChanged(newIngredient = newIngredient)
                    }, 0)
                }
                isOptionEqualToValue = { a, b -> a.ingredientId == b.ingredientId }
                renderInput = { params ->
                    TextField.create {
                        +params
                        label = Box.create { +"Look up ingredient" }
                    }
                }
            }
        }
        if (collectMeasures) {
            Box {
                sx = jso {
                    display = Display.flex
                    flexDirection = FlexDirection.row
                }
                TextField {
                    label = Box.create { +"Amount" }
                    value = amount
                    onChange = { el ->
                        val target = el.target as HTMLInputElement
                        val newAmount = target.value.toDoubleOrNull()?.takeIf { it >= 0 } ?: amount
                        amount = newAmount
                        ingredientChanged(newAmount = newAmount)
                    }
                    fullWidth = true
                }

                TextField {
                    sx = jso {
                        width = 100.pt
                        marginLeft = 10.pt
                    }
                    select = true
                    value = unit.name
                    label = Box.create { +"Unit" }
                    onChange = { event ->
                        val newUnit = WeightUnit.values().first { it.name == event.asDynamic().target.value }
                        unit = newUnit
                        ingredientChanged(newUnit = newUnit)
                    }
                    WeightUnit.values().map {
                        MenuItem {
                            key = it.name
                            value = it.name
                            +it.name
                        }
                    }
                }
            }
        }
    }
}

private external interface IngredientSelectOption {
    var label: String
    var ingredientId: String
}

private fun ingredient(option: IngredientSelectOption?): Ingredient? {
    return option?.let { o -> Storage.getIngredients().firstOrNull { it.id == o.ingredientId } }
}

private fun option(ingredient: Ingredient): IngredientSelectOption? =
    if (ingredient == NO_INGREDIENT) {
        null
    } else {
        jso {
            this.label = ingredient.name
            this.ingredientId = ingredient.id
        }
    }

